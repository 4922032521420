
import { Component, Prop } from 'vue-property-decorator';
import { Profiles, Categories } from './quota-generate-interface'
import Vue from "vue";
import qs from 'qs';
import Login from "@/pages/users/login.vue";
import {USER_LEVEL} from "@/enum/enums";



interface SelectedItems {
  questionNumber: string;
  value: string;
  label: string;
  questionId: string;
  exampleId: string;
  percentage?: number;
}



interface CalculateQuestions {
  questionNumber: string;
  _id: string;
  percentage: number;
}

interface CalculateExamples {
  example: {[k: string]: number};
  percentage: number;
}

@Component
export default class QuotaProfile extends Vue {
  // TODO: GLOBAL MIX에서 Prop로 수정
  // @Prop(String) surveyId!: string;
  private readonly TYPE: string = 'PROFILE';
  private categories: Array<Categories> = [];
  private profiles: Array<Profiles> = [];
  private currentCategory: Categories | null = null;
  private currentItems: Array<SelectedItems> = [];
  private selectedQuestions: {[k: string]: CalculateExamples} = {};

  private calculating: {[k: string]: boolean} = {};
  private calculatingSub: {[k: string]: { [k: string]: boolean }} = {};
  private MAX_COUNT = 10;

  created() {
    (async() => {
      await this.load();
    })();
  }

  async load() {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const { data } = await this.axios({
      method: 'GET',
      url: `/profile/categories/${this.surveyId}`
    });
    this.categories = data;
  }

  async selectProfile(category: Categories) {
    const { category: categoryId } = category;
    this.currentCategory = category;
    // this.profiles = await this.loadQuestions(categoryId);

    const exceptQuestionNumber = this.isInternalUser ? [] : ['SQ1','SQ2','SQ3'];
    this.profiles = (await this.loadQuestions(categoryId)).filter(r => !exceptQuestionNumber.includes(r.questionNumber));
  }

  async loadQuestions(category: string) {
    this.loading = true;
    const { data } = await this.axios({
      method: 'GET',
      url: `/profile/questions/${this.surveyId}/${encodeURIComponent(category)}`
    });
    this.loading = false;
    return data as Profiles[];
  }

  selectItemClass(item: SelectedItems) {
    const { questionId: selectedQuestionId, exampleId: selectedExampleId } = item;
    return !!this.currentItems.find(({ questionId, exampleId }) => questionId === selectedQuestionId && exampleId === selectedExampleId);
  }

  selectItem(item: SelectedItems) {
    const { questionId: selectedQuestionId, exampleId: selectedExampleId } = item;
    const index = this.currentItems.findIndex(({ questionId, exampleId }) => questionId === selectedQuestionId && exampleId === selectedExampleId);
    if (index > -1) {
      this.currentItems.splice(index, 1);
    } else {
      if (this.MAX_COUNT <= this.currentItems.length) {
        alert(this.$t('PROJECT.CREATED.QUOTA.PROFILE_MAX', { max: this.MAX_COUNT }));
        return;
      }
      this.currentItems.push(item);
    }
  }

  async calculateProfile() {
    if (!this.currentCategory) return;
    this.loading = true;
    this.calculating = this.profiles.reduce((prev, cur) => {
      const { questionNumber } = cur;
      prev[questionNumber] = true;
      return prev;
    }, {} as { [k: string]: true });
    this.calculatingSub = this.selectedItems.reduce((prev, cur) => {
      const { questionNumber, values } = cur;
      prev[questionNumber] = values.reduce((p, { value }) => {
        p[value] = true;
        return p;
      }, {} as { [k: string]: boolean });
      return prev;
    }, {} as {[k: string]: { [k: string]: boolean }});

    this.$emit('profileUpdate', this.toParent)

    // console.log(JSON.stringify({ quota: this.toParent }, null , 2));

    const { category } = this.currentCategory;
    const { data } = await this.axios({
      url: `/profile/calculate-available/${this.surveyId}`,
      method: 'GET',
      params: {
        category,
        selectedItems: this.selectedItems,
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    if (data) {
      const { questions, selectedQuestions } = data as { questions: { [k: string]: CalculateQuestions }, selectedQuestions: { [k: string]: CalculateExamples } };
      if (selectedQuestions) this.selectedQuestions = selectedQuestions;
      for (const profile of this.profiles) {
        const { questionNumber: qn } = profile;
        if (questions[qn]) {
          const { percentage } = questions[qn];
          profile.percentage = percentage;
        }
        if (selectedQuestions[qn]) {
          const { percentage } = selectedQuestions[qn];
          profile.selectedPercentage = percentage;
        }
      }
      // await new Promise((resolve) => setTimeout(resolve, 1500));
    }

    this.loading = false;
    this.calculating = {};
    this.calculatingSub = {};
  }

  clear() {
    this.currentCategory = null;
    this.currentItems = [];
    this.$emit('profileUpdate', [])
  }

  get selectedItems() {
    return this.currentItems.reduce((prev, cur) => {
      const { questionNumber: qn, value, label } = cur;
      const index = prev.findIndex(({ questionNumber }) => questionNumber === qn);
      if (index > -1) {
        prev[index].values.push({ value, label });
        return prev;
      } else {
        return prev.concat({ questionNumber: qn, values: [{ value, label }] });
      }
    }, [] as { questionNumber: string, values: { value: string, label: string }[] }[]);
  }

  get toParent() {
    const type = this.TYPE;
    const { category } = this.currentCategory as Categories;

    // TODO: 보기 하나를 쿼터로 잡는 방법
    const [questionNumber] = this.currentItems.map(r => r.questionNumber)

    return [{
      type,
      category,
      questionNumber,
      examples: this.currentItems.map((item) => {
        const { questionNumber, value, label } = item;
        return {
          questionNumber,
          values: [{ questionNumber, value, label, type }]
        }
      }),
    }];
    // TODO: 문항별로 묶어서 쿼터를 잡는 방법

    /*return this.selectedItems.map((item) => {
      const { questionNumber, values } = item;
      // console.log(item)
      return {
        category,
        questionNumber,
        type,
        examples: values.map(({ value, label }) => {
          return {
            questionNumber,
            values: [{ value, label, type: 'PROFILE', questionNumber }]
          }
        }),
      }
    });*/
  }
}
