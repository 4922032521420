
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class test extends Vue {
  @Prop() surveyStep!: number;
  surveySteppers = [
    { name: this.$t('PROJECT.COMPONENTS.REGISTRATION_STEP.FIRST'), step: 1 },
    { name: this.$t('PROJECT.COMPONENTS.REGISTRATION_STEP.SECOND'), step: 2 },
    { name: this.$t('PROJECT.COMPONENTS.REGISTRATION_STEP.THIRD'), step: 3 },
    { name: this.$t('PROJECT.COMPONENTS.REGISTRATION_STEP.FOURTH'), step: 4 },
    { name: this.$t('PROJECT.COMPONENTS.REGISTRATION_STEP.FIFTH'), step: 5 },
    { name: this.$t('PROJECT.COMPONENTS.REGISTRATION_STEP.SIXTH'), step: 6 },
    { name: this.$t('PROJECT.COMPONENTS.REGISTRATION_STEP.SEVENTH'), step: 7 },
  ]
}
