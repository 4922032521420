
import Vue from 'vue';
import Component from 'vue-class-component';
import CreatedNav from "@/pages/project/created/created-nav.vue";
import QuotaDemo from "./quota-demo.vue";
import QuotaProfile from "./quota-profile.vue";
import QuotaGenerate from "./quota-generate.vue";
import { Editable, DemoItem, ProfileCategory } from "./quota-generate-interface";
import RegistrationStep from "@/pages/project/components/registration-step.vue";
import { mixins } from 'vue-class-component'
import { CreatedMixin } from '@/mixins/created-mixin'

@Component({
  components: {
    RegistrationStep,
    QuotaGenerate,
    QuotaProfile,
    QuotaDemo,
    CreatedNav,
  }
})
export default class createdQuota extends mixins(Vue, CreatedMixin) {
  private step = 1;
  private editFlag = false;
  public editable: Editable = {
    age: [],
    region: [],
    currentRegion: [],
  }

  public selectedQuota: {
    AGE: DemoItem[],
    GENDER: DemoItem[],
    REGION: DemoItem[],
  } = {
    AGE: [],
    GENDER: [],
    REGION: [],
  }

  async created(): Promise<void> {
    this.loading = true;
    const info = await this.mixinsLoad();
    await this.getQuota();
    if(info) {
      const { invitationConfirm } = info;
      if (invitationConfirm !== true) this.editFlag = true;
    }
    this.loading = false;
  }

  public selectedProfile: ProfileCategory[] = [];

  next() {
    const { generate } = this.$refs as { generate: QuotaGenerate };
    this.step = 2;
    generate.addListReset();
  }

  async getQuota() {
    const { data } = await this.axios({ url: `/quota/${this.surveyId}` });
    console.log(data);
  }

  profileUpdate(profile: ProfileCategory[]) {
    this.selectedProfile = profile;
  }
}
