import Vue from 'vue'
import Component from 'vue-class-component'

export interface InvalidMessages {
  field: string,
  message: string[],
}

@Component
export class ValidationMixin extends Vue {
  invalidMessages: InvalidMessages[] = [];

  validation(field: string) {
    return this.invalidMessages.find(r => r.field === field) ? false : null;
  }

  invalidMessage(field: string) {
    const invalidMessage = this.invalidMessages.find(r => r.field === field);
    return invalidMessage ? invalidMessage.message.join('\r\n') : null;
  }
}
