
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ValidationMixin, InvalidMessages } from '@/mixins/validation-mixin';
import {
  DemoItem,
  DemoTypes,
  Editable,
  GenerateQuota,
  QuotaRows,
  ProfileCategory,
} from './quota-generate-interface';

@Component
export default class QuotaDemo extends mixins(Vue, ValidationMixin) {
  @Prop() selectedQuota!: {
    AGE: DemoItem[],
    GENDER: DemoItem[],
    REGION: DemoItem[]
  };
  @Prop() selectedProfile!: ProfileCategory[];
  @Prop() editable!: Editable;
  @Prop() editFlag!: boolean;

  private completeCount = 0;
  private addList: DemoTypes[] | string[] = [];
  private quotaRows: { _id?: string, sub: QuotaRows[] }[] = [];

  addListReset() {
    this.addList = [];
  }

  quotaExist(key: DemoTypes | string) {
    const tmp = this.selectedQuota[key as DemoTypes];
    if (tmp && tmp.length) {
      return true;
    }
    if (key === DemoTypes.AGE && this.editable.age.length) {
      return true;
    }

    if (key === DemoTypes.REGION && this.editable.region.length) {
      return true;
    }

    if (this.profileValues.find(r => `${ r.category }-${ r.label }` === key)) {
      return true;
    }

    return false;
  }

  quotaView(key: DemoTypes) {
    const tmp = this.selectedQuota[key];
    if (tmp && tmp.length) {
      return tmp.map(r => r.label);
    }

    if (key === DemoTypes.AGE && this.addList.includes(DemoTypes.AGE)) {
      return this.editable.age.map(r => `${ r.min }-${ r.max }`);
    }

    if (key === DemoTypes.REGION && this.addList.includes(DemoTypes.REGION)) {
      return this.editable.region.map(r => r.value.join('/'));
    }

    const profile = this.profileValues.find(r => `${ r.category }-${ r.label }` === key);
    if (profile) {
      return [profile.label];
    }

    return [];
  }

  async created() {
    this.loading = true;
    await this.load();
    await this.loadDemo();
    this.loading = false;
  }

  async load() {
    try {
      const { result, data, message } = await this.axios.get(`/quota/${ this.surveyId }`);
      if (!result) throw message;

      for (const quota of data) {
        const { sub } = quota;
        this.quotaRows.push({ sub });
      }
    } catch (e) {
      this.validationMessages(e);
    }
  }

  async loadDemo() {
    try {
      const { result, data, message } = await this.axios.get(`/project/${ this.surveyId }`);
      if (!result) throw message;

      const { completeCount } = data;
      this.completeCount = completeCount;
    } catch (e) {
      this.validationMessages(e);
    }
  }

  async save() {
    this.proc(true);
    try {
      const { result, message } = await this.axios.post(`/quota/${ this.surveyId }`, {
        quota: this.quotaRows,
      });

      if (result) {
        this.validationMessages(message, true);
      } else {
        this.validationMessages(message);
      }
      this.invalidMessages = message as InvalidMessages[];
    } catch (e) {
      this.validationMessages(e);
    }
    this.proc(false);
  }

  totalChk() {
    for (const quota of this.quotaRows) {
      const sum = quota.sub
          .map(r => r.wanted)
          .reduce((a, c) => {
            return a + c;
          }, 0);
      console.log(sum, this.completeCount);
      if (this.completeCount !== sum) return false;
    }
    return true;
  }

  async generate() {
    this.proc(true);
    try {
      const quota = this.generateData();
      const { result, data, message } = await this.axios.post(`quota/generate/${ this.surveyId }`, {
        quota,
      });

      if (!result) throw message;

      this.quotaRows.push({ sub: data });
    } catch (e) {
      this.validationMessages(e);
    }
    this.proc(false);
  }

  generateData() {
    const quota = [];
    for (const key of this.addList) {
      const tmp = this.selectedQuota[key as DemoTypes];
      if (tmp && tmp.length) {
        const [type] = tmp.map(r => r.type);
        const examples = tmp.map(r => {
          return {
            values: [r],
          };
        });
        const item = {
          type,
          examples,
        };
        quota.push(item);
      }
    }

    if (this.addList.includes(DemoTypes.AGE)) {
      const { age } = this.editable;
      if (age.length) {
        const ageFiltered = age.filter(({ min, max }) => !isNaN(+min) && !isNaN(+max));
        const ages: GenerateQuota = {
          type: 'AGE_EDITABLE',
          examples: ageFiltered.map(({ min, max }) => {
            const item: DemoItem = {
              type: 'AGE_EDITABLE',
              value: `${ min }-${ max }`,
              min: +min,
              max: +max,
              label: `${ min }-${ max }`,
            };
            return {
              values: [item],
            };
          }),
        };
        quota.push(ages);
      }
    }

    if (this.addList.includes(DemoTypes.REGION)) {
      const { region } = this.editable;
      if (region.length) {
        const regions: GenerateQuota = {
          type: 'REGION_GROUP',
          examples: region.map(({ value }) => {
            return {
              values: value.map(v => ({ value: v, label: v })),
            };
          }),
        };
        quota.push(regions);
      }
    }
    if (this.selectedProfile.length) {
      const profiles: ProfileCategory[] = JSON.parse(JSON.stringify(this.selectedProfile));

      profiles.forEach(profile => {
        const { category } = profile;

        // TODO: 기본
        const selected = profile.examples.filter((exam, idx) => {
          return exam.values.filter(v => this.addList.includes(`${ category }-${ v.label }` as DemoTypes)).length;
        });

        selected.forEach(r => {
          quota.push({
            type: 'PROFILE',
            examples: [r],
          });
        });


        // TODO: 문항 별
        /*const selected = profile.examples.filter((exam,idx) => {
          return exam.values.filter(v => this.addList.includes(`${ category }-${ v.label }` as DemoTypes)).length
        })

        if(selected.length) {
          quota.push({
            type: 'PROFILE',
            examples: [...selected]
          })
        }*/

      });
    }
    console.log(quota);
    return quota;
  }

  quotaRemove(index: number) {
    return this.quotaRows.splice(index, 1);
  }

  quotaFill(index: number) {
    const promptLabel = this.$t('PROJECT.CREATED.QUOTA.PLEASE_INPUT_COUNT');
    if (!(typeof promptLabel === 'string')) return;
    const count = prompt(promptLabel);
    if (count) {
      if (this.quotaRows[index] && !isNaN(+count)) {
        for (const row of this.quotaRows[index].sub) {
          row.wanted = +count;
        }
      }
    }
  }

  quotaPopulate(index: number) {
    if (!this.quotaRows[index]) return;
    // TODO: 추후 작업해야 함

    for (const row of this.quotaRows[index].sub) {
      row.wanted = row.recommend;
    }
    /*const count = Math.ceil(1000 / this.quotaRows[index].sub.length);

    if (!isNaN(+count)) {
      for (const row of this.quotaRows[index].sub) {
        row.wanted = count;
      }
    }*/
  }

  get quotaRowsCalculate() {
    return this.quotaRows.map(({ _id, sub: rows }) => {
      const total = rows.reduce((prev, cur) => {
        const { wanted } = cur;
        return prev + (isNaN(+wanted) ? 0 : +wanted);
      }, 0);
      return {
        total,
        _id,
        rows,
      };
    });
  }

  get addable() {
    const keys = Object.keys(this.selectedQuota);

    this.profileValues.forEach(v => {
      keys.push(`${ v.category }-${ v.label }`);
    });

    return keys;
  }

  get profileValues() {
    const values: { category: string, value: string, label: string }[] = [];
    this.selectedProfile.forEach(profile => {
      const { category } = profile;
      profile.examples.forEach(exam => {
        exam.values.forEach(v => {
          values.push({ category, ...v });
        });
      });
    });
    return values;
  }

  get DemoTypes() {
    return DemoTypes;
  }
}
