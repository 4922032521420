
import Vue from 'vue';
import Component from 'vue-class-component';
import { DemoTypes, DemoSubTypes, Editable, DemoItem, Demo } from './quota-generate-interface';

@Component
export default class QuotaDemo extends Vue {
  private regionSearch = '';
  private types: string[] = [];
  private minAge = 0;
  private maxAge = 0;
  private demo!: Demo;

  private ageEditableValid = {
    invalid: false,
    min: this.minAge,
    max: this.maxAge,
  };

  public editable: Editable = {
    age: [],
    region: [],
    currentRegion: [],
  };

  public selectedQuota: {
    AGE: DemoItem[],
    GENDER: DemoItem[],
    REGION: DemoItem[]
  } = {
    AGE: [],
    GENDER: [],
    REGION: [],
  };

  async created() {
    this.loading = true;
    await this.load();
    await this.getExamples();
    this.loading = false;
  }

  async load() {
    try {
      const { result, data, message } = await this.axios({
        url: `/project/${ this.surveyId }`,
        method: 'GET',
      });

      if (!result) throw message;

      const { demoGraphic } = data;
      const { minAge, maxAge } = demoGraphic;
      this.minAge = minAge;
      this.maxAge = maxAge;

      this.$emit('update:editable', this.editable);
      this.$emit('update:selectedQuota', this.selectedQuota);
    } catch (e) {
      this.validationMessages(e);
    }
  }

  async getExamples() {
    const { result, data } = await this.axios({
      url: `/quota/examples/${ this.surveyId }`,
    });
    if (result) {
      const { types, demo } = data;
      this.types = types;
      this.demo = demo;
    }
  }

  minCheck(v: number, idx: number) {
    const age = this.editable.age[idx - 1];
    const min = age ? age.max + 1 : this.minAge;
    const { max } = this.editable.age[idx];
    if (v < min || v >= max) {
      this.editable.age[idx].min = min;
      this.ageEditableValid.min = min;
      this.ageEditableValid.max = max;
      this.ageEditableValid.invalid = true;
    }
  }

  maxCheck(v: number, idx: number) {
    const age = this.editable.age[idx + 1];
    const max = age ? age.min - 1 : this.maxAge;
    const { min } = this.editable.age[idx];
    if (v <= min || v > max) {
      this.editable.age[idx].max = max;
      this.ageEditableValid.min = min;
      this.ageEditableValid.max = max;
      this.ageEditableValid.invalid = true;
    }
  }

  checkAll(type: DemoTypes, subType: DemoSubTypes, editable = false) {
    if (editable) {
      const item = this.demo[type][subType].map(r => r.value);
      const exist = this.editable.currentRegion;
      this.editable.currentRegion = [];
      if (exist.length !== item.length) this.editable.currentRegion.push(...item);
      return;
    }
    const exists = this.selectedQuota[type].filter(({ type: _type }) => _type === subType);
    this.selectedQuota[type] = this.selectedQuota[type].filter(({ type: _type }) => _type !== subType);
    const item = this.demo[type][subType];
    if (exists.length !== item.length) this.selectedQuota[type].push(...item);
  }

  ageEditableAdd() {
    const min = this.lastEditableAge;
    let max = min + 9;
    max = max > this.maxAge ? this.maxAge : max;

    if (min < this.maxAge) {
      this.editable.age.push(
          { min, max },
      );
    }
  }

  ageEditableRemove(index: number) {
    this.editable.age.splice(index, 1);
  }

  regionEditableAdd() {
    const cur = this.editable.currentRegion.slice();
    cur.sort();

    if (!cur.length) return;

    const isExist = this.editable.region.find(r => r.value.toString() === cur.toString());
    if (!isExist) this.editable.region.push({ value: cur });

    this.editable.currentRegion = [];
  }

  regionEditableRemove(index: number) {
    this.editable.region.splice(index, 1);
  }

  get lastEditableAge() {
    const tmpLen = this.editable.age.length;
    if (tmpLen === 0) return this.minAge;
    return this.editable.age[tmpLen - 1].max + 1;
  }

  get examples() {
    return {
      AGE5: this.demo.AGE.AGE5,
      AGE10: this.demo.AGE.AGE10,
      GENDER: this.demo.GENDER.GENDER,
    };
  }

  get regions() {
    const { REGION } = this.demo.REGION;

    if (this.regionSearch) {
      return REGION.filter(r => r.value.includes(this.regionSearch.toUpperCase()));
    }
    return REGION;
  }

  get regionGroups() {
    const { REGION_GROUP } = this.demo.REGION;
    if (this.regionSearch) {
      return REGION_GROUP.filter(r => r.value.includes(this.regionSearch.toUpperCase()));
    }
    return REGION_GROUP;
  }

  get RegionGroupMap() {
    const map: Map<string, string> = new Map();
    return this.regionGroups.reduce((acc, cur) => {
      const { value, label } = cur;
      acc.set(value, label);
      return acc;
    }, map);
  }

  get DemoTypes() {
    return DemoTypes;
  }

  get DemoSubTypes() {
    return DemoSubTypes;
  }
}
