export interface Editable {
  age: Array<{ min: number, max: number }>;
  region: Array<{ value: string[] }>;
  currentRegion: string[];
}

export interface DemoItem {
  type: string;
  value: string;
  label: string;
  min?: number;
  max?: number;
}

export interface DemoType {
  AGE10: DemoItem[],
  AGE5: DemoItem[],
  AGE_EDITABLE: DemoItem[],
  GENDER: DemoItem[],
  REGION: DemoItem[],
  REGION_GROUP: DemoItem[],
}

export interface Demo {
  AGE: DemoType,
  GENDER: DemoType,
  REGION: DemoType,
}

export interface QuotaArray {
  type: string;
  value: string[],
  questionNumber?: string;
  range?: Array<{ min: number, max: number }>
}

export interface Generate {
  quota: Array<GenerateQuota>
}

export interface GenerateQuota {
  type: string,
  examples: Array<GenerateExamples>
}

export interface GenerateExamples {
  questionNumber?: string;
  values: Array<{ value: string, min?: number, max?: number, questionNumber?: string }>;
}

export interface QuotaRows {
  _id?: string;
  types: string[];
  quotaArray: QuotaArray[];
  labels: string;
  wanted: number;
  recommend: number;
}

export interface Profiles {
  percentage?: number,
  selectedPercentage?: number,
  _id: string;
  questionNumber: string;
  title: string;
  examples: Array<{ percentage?: number, _id: string, text: string, values: { value: string, label: string }[] }>;
  regDate: string;
  use: boolean;
  label: string;
}

export interface Categories {
  _id: string;
  category: string;
  label: string;
}

export interface ProfileCategory extends Profiles, Categories{
  value: string,
  type: string,
}

export enum DemoTypes {
  AGE = 'AGE',
  GENDER = 'GENDER',
  REGION = 'REGION',
}


export enum DemoSubTypes {
  AGE5 = 'AGE5',
  AGE10 = 'AGE10',
  AGE_EDITABLE = 'AGE_EDITABLE',
  GENDER = 'GENDER',
  REGION = 'REGION',
  REGION_GROUP = 'REGION_GROUP',
}
